import MultipleOccupancyBreakdown from './multiple-occupancy-breakdown';
import MultipleOccupancyBreakdownV2 from './multiple-occupancy-breakdown-v2';

import OccupancyBreakdown from './occupancy-breakdown';
import OccupancyBreakdownV2 from './occupancy-breakdown-v2';

import OccupancySummary from './occupancy-summary';

export default class OccupancyMain {
    constructor(engineForm, isCorporate, initial = {}) {
        this._mainContainer = document.querySelector(
            '.js-roi-search-engine-occupancy-container'
        );

        if (this._mainContainer === null) {
            return;
        }

        this._MAX_ROOMS = 4;
        this._engineForm = engineForm;

        let _dataPrefixes;
        try {
            _dataPrefixes = this._engineForm.dataset.destinationPrefixes;
        } catch (e) {
            _dataPrefixes = this._engineForm.getAttribute('data-destination-prefixes');
        }
        this._destinationPrefixes = (_dataPrefixes) ? JSON.parse(_dataPrefixes) : [];

        const isSingleOccupancy = !!(this._engineForm
                .querySelector('.js-roi-search-engine-single-occupancy')),
            isOccupancyDropdown = !!(this._engineForm
                .querySelector('.roi-search-engine-occupancy--v2'));

        if (isOccupancyDropdown === false) {
            this._initV1(isSingleOccupancy);
        } else if (isOccupancyDropdown) {
            this._initV2(isSingleOccupancy);
        }

        this._occupancySummary = new OccupancySummary(this);
        this._initValues(isCorporate, initial);
        this._openBreakdown();
    }

    getEngineForm() { return this._engineForm; }

    getHotelData() { return this._hotelData; }

    getMaxRooms() { return this._MAX_ROOMS; }

    updateSummary(selectedOccupancies, roomCounter) {
        this._occupancySummary.updateSummary(selectedOccupancies, roomCounter);
    }

    _initV1(isSingleOccupancy) {
        if (isSingleOccupancy === false) {
            this._occupancyBreakdown = new MultipleOccupancyBreakdown(this);
        } else {
            this._occupancyBreakdown = new OccupancyBreakdown(this);
        }
    }

    _initV2(isSingleOccupancy) {
        if (isSingleOccupancy === false) {
            this._occupancyBreakdown = new MultipleOccupancyBreakdownV2(this);
        } else {
            this._occupancyBreakdown = new OccupancyBreakdownV2(this);
        }
    }

    _initValues(isCorporate, initial) {
        try {
            this._occupancyData = JSON.parse(this._mainContainer.dataset.occupancyData);
        } catch (e) {
            this._occupancyData = JSON.parse(this._mainContainer.getAttribute('data-occupancy-data'));
        }
        initial = this._checkOcuupancies(this._mainContainer, initial);

        if (isCorporate) {
            let destinationCodeField = this._engineForm.querySelector(
                '.js-roi-search-engine-destination-code'
            );
            if (!destinationCodeField) {
                destinationCodeField = this._engineForm.querySelector(
                    '#id_coddestino'
                );
            }
            this._resetOccupancies(destinationCodeField.value.trim(), initial || true);
            destinationCodeField.addEventListener('input', (e) => {
                this._resetOccupancies(e.currentTarget.value, initial);
            });
        } else {
            this._resetOccupancies(Object.keys(this._occupancyData)[0], initial || true);
        }
    }

    _checkOcuupancies(mainContainer, initial) {
        this._checkAdults(mainContainer, initial);
        this._checkChildren(mainContainer, initial);
        return initial;
    }

    _checkChildren(mainContainer, initial) {
        const childreAges = new URL(window.location.href).searchParams.get('children-ages');

        if (childreAges) {
            this._children = childreAges;
        } else {
            try {
                this._children = mainContainer.dataset.occupancyInitialAges;
            } catch (e) {
                this._children = mainContainer.getAttribute('data-occupancy-initial-aages');
            }
        }

        if (this._children) {
            initial.ages = this._children.replaceAll(',', ';');
            initial.children = this._children.split(',').length;
        }
        return initial;
    }

    _checkAdults(mainContainer, initial) {
        const adults = new URL(window.location.href).searchParams.get('adults');

        if (adults) {
            this._adults = adults;
        } else {
            try {
                this._adults = mainContainer.dataset.occupancyInitialAdults;
            } catch (e) {
                this._adults = mainContainer.getAttribute('data-occupancy-initial-adults');
            }
        }


        if (this._adults) {
            initial.adults = parseInt(this._adults, 10);
        }
        return initial;
    }

    _resetOccupancies(hotelCode, initial = false) {
        if (this._destinationPrefixes.includes(hotelCode[0])) {
            this._hotelData = this._getCombinedOccupancyDataForDestination(hotelCode);
            this._mainContainer.style.display = 'none';
        } else {
            if (hotelCode && hotelCode !== 'todos' && hotelCode !== 'undefined') {
                this._mainContainer.style.display = 'flex';
            } else {
                this._mainContainer.style.display = 'none';
            }
            this._hotelData = this._occupancyData[hotelCode];
            if (!Object.keys(initial).length && this._hotelData) {
                initial = {
                    adults: Object.keys(this._hotelData.allowedOccupancies)[0]
                };
            }
        }
        this._occupancyBreakdown.launch(initial);
    }

    /**
     * Retrieves for a given destination the maximum allowed children for each
     * allowed adults occupancy of all hotels, as well as the maximum children
     * age of all of them
     */
    _getCombinedOccupancyDataForDestination(destinationCode) {
        const destinationHotels = this._engineForm.querySelectorAll(
                `[data-destination="${destinationCode}"]`
            ),
            data = {
                allowedOccupancies: {},
                childrenMaxAge: 0
            };
        destinationHotels.forEach((hotel) => {
            let hotelData;
            try {
                hotelData = this._occupancyData[hotel.dataset.value];
            } catch (e) {
                hotelData = this._occupancyData[hotel.getAttribute('data-value')];
            }
            data.childrenMaxAge = Math.max(data.childrenMaxAge, hotelData.childrenMaxAge);
            Object.keys(hotelData.allowedOccupancies).forEach((adults) => {
                if (data.allowedOccupancies.hasOwnProperty(adults)) {
                    data.allowedOccupancies[adults] = Math.max(
                        data.allowedOccupancies[adults], hotelData.allowedOccupancies[adults]
                    );
                } else {
                    data.allowedOccupancies[adults] = hotelData.allowedOccupancies[adults];
                }
            });
        });
        return data;
    }

    _openBreakdown() {
        this._occupancySummary.getSummary().addEventListener('click', () => {
            const calendar = document.querySelector('#roicalendar');

            if (calendar !== null && calendar.classList.contains('show-calendar')) {
                calendar.classList.remove('show-calendar');
            }
            this._occupancyBreakdown.open();
        });
    }
}
